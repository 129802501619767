import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";

import { countryOptions, classesOptions } from "../variables/countryOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faTrash, faHome, faFolder, faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "../css/App.css";

function Form() {
  const { user } = UserAuth();
  const navigate = useNavigate();

  // State for input values
  const [client, setClient] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  // const [differentiators, setDifferentiators] = useState("");

  const [strategy, setStrategy] = useState("");
  // const [attributes, setAttributes] = useState("");
  const [maxChars, setMaxChars] = useState(15); // Initial value for max_chars
  const [specialChars, setSpecialChars] = useState(true); // Initial value for special_chars
  const [localSpelling, setLocalSpelling] = useState("UK"); // Initial value for local_spelling
  const [countries, setCountries] = useState([]); // Initial value for local_spelling
  const [classes, setClasses] = useState([]); // Initial value for local_spelling
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [differentiators, setDifferentiators] = useState([]);
  const [testProject, setTestProject] = useState(false); // Initial value for local_spelling
  const [inputValue, setInputValue] = useState("");
  const [inputValueDifferentiator, setInputValueDifferentiator] = useState("");

  const handleAddAttribute = (e) => {
    e.preventDefault(); // Prevent form submission
    if (inputValue && !attributes.includes(inputValue)) {
      setAttributes([...attributes, inputValue]);
      setInputValue(""); // Clear input field after adding
    }
  };

  const handleAddDifferentiator = (e) => {
    e.preventDefault(); // Prevent form submission
    if (inputValueDifferentiator && !differentiators.includes(inputValueDifferentiator)) {
      setDifferentiators([...differentiators, inputValueDifferentiator]);
      setInputValueDifferentiator(""); // Clear input field after adding
    }
  };

  const handleRemoveAttribute = (attribute) => {
    setAttributes(attributes.filter((d) => d !== attribute));
  };

  const handleRemoveDifferentiator = (differentiator) => {
    setDifferentiators(differentiators.filter((d) => d !== differentiator));
  };

  const host = process.env.REACT_APP_SERVER;

  useEffect(() => {
    if (user === null) {
      navigate("/signin");
    }
  }, [user, navigate]);

  // Normalized onChange handler
  const handleCountriesChange = (option) => {
    console.log("handleCountriesChange");
    // Check if 'option' is an array, if not, make it an array
    const newValue = Array.isArray(option) ? option : [option];
    setCountries(newValue);
  };

  const handleCreate = async () => {
    setLoading(true); // Set loading state to true
    try {
      const formData = {
        client,
        name,
        category,
        description,
        differentiators,
        brand_strategy: strategy,
        attributes: attributes,
        max_chars: maxChars,
        special_chars: specialChars,
        local_spelling: localSpelling,
        countries: countries.flatMap((country) => (Array.isArray(country.value) ? country.value : [country.value])),
        nice_classes: classes.map((cls) => cls.value),
        test_project: testProject,
        status: "created",
      };

      console.log(formData);

      const response = await fetch(`${host}/api/projects`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const responseData = await response.json();
        const { project } = responseData.data;

        // Extract the projectId from the server response
        const projectId = project.id;

        setSuccess(true); // Set success state to true
        navigate(`/projects/${projectId}`);
        setTimeout(() => {
          navigate(`/projects/${projectId}`); // Navigate to the project page using the extracted projectId

          // Scroll to the top of the page after navigating
          window.scrollTo({
            top: 0,
            behavior: "smooth", // You can use "auto" if you don't want smooth scrolling
          });
        }, 500); // Delay for 1.5 seconds
      } else {
        // Handle error case
        console.error("Failed to create project");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!user?.email) {
    return null;
  }
  const industryValueRenderer = (selected, _options) => {
    return selected.length ? selected.map(({ value, label }) => `${label.split(",")[0].trim()} `) : "Select all industries of interest";
  };

  const countryValueRenderer = (selected, _options) => {
    return selected.length ? selected.map(({ value, label }) => `${label.split(",")[0].trim()} `) : "Select all countries of interest";
  };

  return (
    <div className="container mx-auto">
      <div className="text-sm breadcrumbs pb-5">
        <ul>
          <li>
            <Link className="text-secondary" to={`/`}>
              <FontAwesomeIcon icon={faHome} size="1x" />
              <a className="pl-2">Home</a>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/projects`}>
              <FontAwesomeIcon icon={faFolder} size="1x" />
              <a className="pl-2">Projects</a>
            </Link>
          </li>
        </ul>
      </div>
      <div className="prose w-full max-w-6xl ">
        <div className="w-full pl-5 pr-5">
          <div className="flex flex-col gap-5">
            <h2 className="">Client Information</h2>
            <div className="">
              <input type="text" placeholder="Client" className="select select-bordered w-full bg-none textarea-secondary" value={client} onChange={(e) => setClient(e.target.value)} />
            </div>
            <div className="flex flex-col">
              <input type="text" placeholder="Project Code Name" className="select select-bordered w-full bg-none textarea-secondary" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
          </div>
          <div className="section flex flex-col gap-5">
            <h2>Brand Details</h2>
            <div className="flex flex-col gap-3">
              <label className="">Business Category</label>
              <textarea type="text" placeholder="In simple terms, what category is your business in? E.g. Home insurance, cannabis, supply chain management" className="textarea textarea-secondary w-full" value={category} onChange={(e) => setCategory(e.target.value)} />
            </div>
            <div className="flex flex-col gap-3">
              <label className="">Brand Strategy</label>
              <textarea type="text" placeholder="How would you best describe your brand strategy?" className="textarea textarea-secondary w-full" value={strategy} onChange={(e) => setStrategy(e.target.value)} />
            </div>
            <div className="flex flex-col gap-3">
              <label className="">Product Description</label>
              <textarea type="text" placeholder="Please describe your product or service in detail. What functional value does it offer, and how does it work?" className="textarea textarea-secondary w-full " value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className="flex flex-col gap-3">
              <label className="">Personality Attributes</label>
              <form onSubmit={handleAddAttribute} className="">
                <input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder="What personality attributes would you like your name to embody? Please share 3-5 adjectives. E.g. Calming, Caring, Patient" className="textarea textarea-secondary w-full" />
                <div className="flex justify-center">
                  <button type="submit" className="btn btn-secondary mt-5 remove-pill text-neutral ">
                    Add
                  </button>
                </div>
              </form>
              <div className="attribute-pills">
                {attributes.map((attribute, index) => (
                  <span key={index} className="pill badge badge-primary mr-5 badge-outline" onClick={() => handleRemoveAttribute(attribute)}>
                    {attribute} x
                  </span>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <label className="">Differentiators</label>
              <form onSubmit={handleAddDifferentiator} className="">
                <input
                  type="text"
                  value={inputValueDifferentiator}
                  onChange={(e) => setInputValueDifferentiator(e.target.value)}
                  placeholder="Being as succinct as possible, in what way(s) is your product or service different and/or better than alternatives? E.g. Safer, more durable, environmentally friendly"
                  className="textarea textarea-secondary w-full"
                />
                <div className="flex justify-center">
                  <button type="submit" className="btn btn-secondary mt-5 remove-pill text-neutral ">
                    Add
                  </button>
                </div>
              </form>
              <div className="attribute-pills">
                {differentiators.map((a, index) => (
                  <span key={index} className="pill badge badge-primary mr-5 badge-outline" onClick={() => handleRemoveDifferentiator(a)}>
                    {a} x
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="section flex flex-col gap-5">
            <h2>Model Parameters</h2>
            <div className="flex flex-col">
              <label>Max Characters</label>
              <label className="text-sm font-semibold py-5">What is the maximum character count you would consider, including spaces and special characters?</label>
              <input
                type="range"
                className="range range-primary"
                min="3"
                max="25" // Adjust the maximum value as needed
                value={maxChars}
                onChange={(e) => setMaxChars(parseInt(e.target.value))}
              />
              <p className="text-xs text-gray-500">Current Value: {maxChars}</p>
            </div>
            <div className="flex flex-col">
              <label>Special Characters</label>
              <label className="text-sm font-semibold py-5">Are you open to names that include special characters? These include ampersands, hyphens, backslashes, accents and numbers.</label>

              <div className="form-control">
                <label className="label cursor-pointer ">
                  <span className="label-text ">Yes</span>
                  <input type="radio" className="radio radio-primary" name="specialChars" value={true} checked={specialChars === true} onChange={() => setSpecialChars(true)} />
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">No</span>
                  <input type="radio" className="radio radio-primary" name="specialChars" value={false} checked={specialChars === false} onChange={() => setSpecialChars(false)} />
                </label>
              </div>
            </div>
            <div className="flex flex-col">
              <label>Local Spelling</label>
              <label className="text-sm font-semibold py-5">Do you require your name to embody American or English spelling?</label>
              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">United Kingdom</span>
                  <input type="radio" className="radio radio-primary" name="localSpelling" value="UK" checked={localSpelling === "UK"} onChange={() => setLocalSpelling("UK")} />
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">American</span>
                  <input type="radio" className="radio radio-primary" name="localSpelling" value="US" checked={localSpelling === "US"} onChange={() => setLocalSpelling("US")} />
                </label>
              </div>
            </div>
          </div>

          <div className="section flex flex-col gap-5">
            <h2>Trademark Parameters</h2>
            <MultiSelect
              valueRenderer={countryValueRenderer}
              options={countryOptions}
              value={countries}
              onChange={handleCountriesChange}
              labelledBy={"In which countries do you require trademark protection? Select all that apply"}
              isCreatable={false}
              hasSelectAll={false}
              className="pb-5 dark text-sm"
            />
            <MultiSelect valueRenderer={industryValueRenderer} options={classesOptions} value={classes} onChange={setClasses} labelledBy={"Select Nice Classes"} isCreatable={false} hasSelectAll={false} className="pb-5 dark text-sm" />
          </div>

          <div className="flex flex-col">
            <h2>Test Project</h2>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">No</span>
                <input type="radio" className="radio radio-primary" name="" value="" checked={!testProject} onChange={() => setTestProject(false)} />
              </label>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">Yes</span>
                <input type="radio" className="radio radio-primary" name="" value="" checked={testProject} onChange={() => setTestProject(true)} />
              </label>
            </div>
          </div>

          <div className="flex justify-center p-5">
            <button className={`btn btn-secondary text-neutral ${loading ? "btn-disabled" : ""}`} onClick={handleCreate} disabled={loading}>
              {loading ? "..." : "CREATE"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
