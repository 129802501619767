import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyA5ei9pRbASb0eP0OhwIXNWkWJObrHrsic",
	authDomain: "as-yet-unknown.firebaseapp.com",
	projectId: "as-yet-unknown",
	storageBucket: "as-yet-unknown.appspot.com",
	messagingSenderId: "245707384896",
	appId: "1:245707384896:web:8a97ff35d100d1280857a5",
	measurementId: "G-1LD78BRFB3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const googleProvider = new GoogleAuthProvider();
export const auth = getAuth(app);
export const db = getFirestore(app);
