import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faTrash, faHome, faFolder } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../css/App.css";

function App() {
  const { user, logOut } = UserAuth();

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (user === null) {
      navigate("/signin");
    }
  }, [user, navigate]);

  if (!user?.email) {
    return null;
  }

  const getCustomAvatar = (displayName) => {
    const avatarURLs = {
      "Chris Daly": "https://ca.slack-edge.com/T05A5R2RSD6-U059D6DLDJ9-25c0116d4c68-512", //"https://ca.slack-edge.com/T05A5R2RSD6-U05A5R9T2HE-e311a666d597-512",
      "Jack Wimmer": "https://ca.slack-edge.com/T05A5R2RSD6-U059D6DLDJ9-25c0116d4c68-512",
      "Mark Liney": "https://ca.slack-edge.com/T05A5R2RSD6-U0599FVSH2A-bcc951407291-192",
      "Eden Goonesekera": "https://ca.slack-edge.com/T05A5R2RSD6-U059JK4HUVA-707968a81ad9-512",
      "Lucas Galan": "https://ca.slack-edge.com/T05A5R2RSD6-U0599G2HB62-2dd1c3f2abc0-512",
      // Add more mappings for other users if needed
    };
    return avatarURLs[displayName] || user.photoURL || "/default-avatar.jpg"; // Use user's photoURL if available, or fallback to default avatar
  };

  console.log("user", user);
  return (
    <body className="animate-in">
      <main className="content relative">
        <div className="anim anim--top z-5">
          <svg viewBox="0 0 1588 1400" width="1688" height="1400" preserveAspectRatio="xMaxYMax slice" style={{ width: "100%", height: "100%", transform: "translate3d(0px, -60px, 0px)", contentVisibility: "visible" }}>
            <defs>
              <clipPath id="__lottie_element_2">
                <rect width="1688" height="1500" x="0" y="0"></rect>
              </clipPath>
              <clipPath id="__lottie_element_4">
                <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
              </clipPath>
              <clipPath id="__lottie_element_5">
                <path
                  fill="#ffffff"
                  clipRule="nonzero"
                  d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
                  fillOpacity="1"
                ></path>
              </clipPath>
              <clipPath id="__lottie_element_65">
                <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
              </clipPath>
              <clipPath id="__lottie_element_66">
                <path
                  fill="#ffffff"
                  clipRule="nonzero"
                  d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
                  fillOpacity="1"
                ></path>
              </clipPath>
            </defs>
            <g clip-path="url(#__lottie_element_2)">
              <g clip-path="url(#__lottie_element_65)" transform="matrix(0,1,1.0015000104904175,0,0,0)" opacity="1" style={{ display: "block" }}>
                <g clip-path="url(#__lottie_element_66)">
                  <g transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.4398067593574524,0,354.4216003417969,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.31330469250679016,0,177.01348876953125,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.261483758687973,0,29.25746726989746,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.21034105122089386,0,-90.2864761352539,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.8920289874076843,0,1368.3941650390625,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.7346117496490479,0,932.475830078125,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.6081677675247192,0,570.2960815429688,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.43124061822891235,0,301.9194030761719,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.27493157982826233,0,124.41124725341797,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.1867559850215912,0,5.345733642578125,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,1.1005114316940308,0,1172.006591796875,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.7269718647003174,0,712.8243408203125,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.4754166901111603,0,411.8905944824219,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.31886982917785645,0,211.63739013671875,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.2528867721557617,0,60.247535705566406,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.21800349652767181,0,-69.37810516357422,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g clip-path="url(#__lottie_element_4)" transform="matrix(0,1,-1.0015000104904175,0,1688,0)" opacity="1" style={{ display: "block" }}>
                <g clip-path="url(#__lottie_element_5)">
                  <g transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.4398067593574524,0,354.4216003417969,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.31330469250679016,0,177.01348876953125,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.261483758687973,0,29.25746726989746,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.21034105122089386,0,-90.2864761352539,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.8920289874076843,0,1368.3941650390625,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.7346117496490479,0,932.475830078125,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.6081677675247192,0,570.2960815429688,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.43124061822891235,0,301.9194030761719,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.27493157982826233,0,124.41124725341797,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.1867559850215912,0,5.345733642578125,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,1.1005114316940308,0,1172.006591796875,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.7269718647003174,0,712.8243408203125,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.4754166901111603,0,411.8905944824219,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.31886982917785645,0,211.63739013671875,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.2528867721557617,0,60.247535705566406,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.21800349652767181,0,-69.37810516357422,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className="mt-10 pt-25 z-10">
          <img src="/logo.svg" alt="Monika Logo" className="logo" />
          <div className="text-center">
            <h3 className="title">A moniker delivery system.</h3>
            {user?.displayName && (
              <div className="flex flex-col items-center pt-10">
                <span className="text-lg">Welcome back {user.displayName.split(" ")[0]}</span>
                <img src={getCustomAvatar(user.displayName)} alt="Custom Avatar" className="w-10 h-10 rounded-full mt-2 cursor-pointer" onClick={handleSignOut} />
              </div>
            )}
            <div className="flex justify-center">
              <Link className="" to={`/projects`}>
                <button className="btn btn-secondary text-neutral mt-5">Projects</button>
              </Link>
            </div>
            <div className="flex justify-center">
              <Link className="" to={`/processes`}>
                <button className="btn btn-secondary text-neutral mt-5">Processes</button>
              </Link>
            </div>
            <div className="flex justify-center">
              <Link className="" to={`/trademarking`}>
                <button className="btn btn-secondary text-neutral mt-5">Trademarking</button>
              </Link>
            </div>
            {/* <button type="submit" className="btn btn-secondary mt-5 remove-pill text-neutral" onClick={handleGoogleSignIn}>
              Sign In
            </button> */}
          </div>
        </div>
        <div className="anim anim--bottom z-5">
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1688 1500" width="1688" height="1500" preserveAspectRatio="xMaxYMax slice" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)", contentVisibility: "visible" }}>
            <defs>
              <clipPath id="__lottie_element_126">
                <rect width="1688" height="1500" x="0" y="0"></rect>
              </clipPath>
              <clipPath id="__lottie_element_128">
                <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
              </clipPath>
              <clipPath id="__lottie_element_129">
                <path
                  fill="#ffffff"
                  clip-rule="nonzero"
                  d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
                  fill-opacity="1"
                ></path>
              </clipPath>
              <clipPath id="__lottie_element_189">
                <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
              </clipPath>
              <clipPath id="__lottie_element_190">
                <path
                  fill="#ffffff"
                  clip-rule="nonzero"
                  d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
                  fill-opacity="1"
                ></path>
              </clipPath>
            </defs>
            <g clip-path="url(#__lottie_element_126)">
              <g clip-path="url(#__lottie_element_189)" transform="matrix(0,1,1.0015000104904175,0,0,0)" opacity="1" style={{ display: "block" }}>
                <g clip-path="url(#__lottie_element_190)">
                  <g transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.4792477488517761,0,482.44464111328125,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.38750216364860535,0,262.7000732421875,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.27721554040908813,0,105.91207885742188,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.2403918206691742,0,-30.119531631469727,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.8024939894676208,0,1129.4908447265625,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.6744676828384399,0,730.1572875976562,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.5132513046264648,0,417.35101318359375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.34101924300193787,0,200.5652313232422,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.22042274475097656,0,58.14256286621094,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.1703125238418579,0,-45.89611053466797,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,1.258392095565796,0,1505.657470703125,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.9356011748313904,0,934.0265502929688,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.5900695323944092,0,556.2185668945312,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.39405518770217896,0,308.4035949707031,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.27779099345207214,0,136.5206756591797,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.2349196821451187,0,-2.407461166381836,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g clip-path="url(#__lottie_element_128)" transform="matrix(0,1,-1.0015000104904175,0,1688,0)" opacity="1" style={{ display: "block" }}>
                <g clip-path="url(#__lottie_element_129)">
                  <g transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.4792477488517761,0,482.44464111328125,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.38750216364860535,0,262.7000732421875,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.27721554040908813,0,105.91207885742188,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.2403918206691742,0,-30.119531631469727,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.8024939894676208,0,1129.4908447265625,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.6744676828384399,0,730.1572875976562,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.5132513046264648,0,417.35101318359375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.34101924300193787,0,200.5652313232422,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.22042274475097656,0,58.14256286621094,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.1703125238418579,0,-45.89611053466797,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,1.258392095565796,0,1505.657470703125,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.9356011748313904,0,934.0265502929688,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.5900695323944092,0,556.2185668945312,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.39405518770217896,0,308.4035949707031,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.27779099345207214,0,136.5206756591797,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.2349196821451187,0,-2.407461166381836,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className="anim anim--left">
          <svg viewBox="0 0 1500 1688" width="1500" height="1688" preserveAspectRatio="xMaxYMax slice" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)", contentVisibility: "visible" }}>
            <defs>
              <clipPath id="__lottie_element_250">
                <rect width="1500" height="1688" x="0" y="0"></rect>
              </clipPath>
              <clipPath id="__lottie_element_252">
                <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
              </clipPath>
              <clipPath id="__lottie_element_253">
                <path
                  fill="#ffffff"
                  clip-rule="nonzero"
                  d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
                  fill-opacity="1"
                ></path>
              </clipPath>
              <clipPath id="__lottie_element_313">
                <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
              </clipPath>
              <clipPath id="__lottie_element_314">
                <path
                  fill="#ffffff"
                  clip-rule="nonzero"
                  d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
                  fill-opacity="1"
                ></path>
              </clipPath>
            </defs>
            <g clip-path="url(#__lottie_element_250)">
              <g clip-path="url(#__lottie_element_313)" transform="matrix(1,0,0,-1.0015000104904175,0,1688)" opacity="1" style={{ display: "block" }}>
                <g clip-path="url(#__lottie_element_314)">
                  <g transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.446868360042572,0,377.3431091308594,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.3265891671180725,0,192.35499572753906,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.2643004059791565,0,42.98186492919922,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.21572139859199524,0,-79.51406860351562,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.8759984374046326,0,1325.6204833984375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.7238434553146362,0,896.2523193359375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.5911737680435181,0,542.9124755859375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.41508719325065613,0,283.7727355957031,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.26517221331596375,0,112.54637145996094,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.18381191790103912,0,-3.8287105560302734,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,1.1287786960601807,0,1231.744140625,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.7643253207206726,0,752.4288940429688,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.4959443211555481,0,437.7313537597656,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.3323311507701874,0,228.96261596679688,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.25734567642211914,0,73.90364074707031,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.22103221714496613,0,-57.38753890991211,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g clip-path="url(#__lottie_element_252)" transform="matrix(1,0,0,1.0015000104904175,0,0)" opacity="1" style={{ display: "block" }}>
                <g clip-path="url(#__lottie_element_253)">
                  <g transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.446868360042572,0,377.3431091308594,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.3265891671180725,0,192.35499572753906,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.2643004059791565,0,42.98186492919922,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.21572139859199524,0,-79.51406860351562,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.8759984374046326,0,1325.6204833984375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.7238434553146362,0,896.2523193359375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.5911737680435181,0,542.9124755859375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.41508719325065613,0,283.7727355957031,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.26517221331596375,0,112.54637145996094,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.18381191790103912,0,-3.8287105560302734,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,1.1287786960601807,0,1231.744140625,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.7643253207206726,0,752.4288940429688,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.4959443211555481,0,437.7313537597656,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.3323311507701874,0,228.96261596679688,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.25734567642211914,0,73.90364074707031,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.22103221714496613,0,-57.38753890991211,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className="anim anim--right">
          <svg viewBox="0 0 1500 1688" width="1500" height="1688" preserveAspectRatio="xMaxYMax slice" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)", contentVisibility: "visible" }}>
            <defs>
              <clipPath id="__lottie_element_374">
                <rect width="1500" height="1688" x="0" y="0"></rect>
              </clipPath>
              <clipPath id="__lottie_element_376">
                <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
              </clipPath>
              <clipPath id="__lottie_element_377">
                <path
                  fill="#ffffff"
                  clip-rule="nonzero"
                  d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
                  fill-opacity="1"
                ></path>
              </clipPath>
              <clipPath id="__lottie_element_437">
                <path d="M0,0 L1500,0 L1500,844 L0,844z"></path>
              </clipPath>
              <clipPath id="__lottie_element_438">
                <path
                  fill="#ffffff"
                  clip-rule="nonzero"
                  d=" M0,0 C0,0 0,843.1890258789062 0,843.1890258789062 C0,843.1890258789062 266.5,843.1920166015625 305.5,843.1920166015625 C410,843.1920166015625 544.6900024414062,775.697998046875 544.6900024414062,655.614990234375 C544.6900024414062,490.6549987792969 706.5,335.625 911.625,335.625 C911.625,148.5 1187,0 1500,0 C1391,0 0,0 0,0"
                  fill-opacity="1"
                ></path>
              </clipPath>
            </defs>
            <g clip-path="url(#__lottie_element_374)">
              <g clip-path="url(#__lottie_element_437)" transform="matrix(1,0,0,-1.0015000104904175,0,1688)" opacity="1" style={{ display: "block" }}>
                <g clip-path="url(#__lottie_element_438)">
                  <g transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.4902619421482086,0,518.1962280273438,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.4082224667072296,0,286.6288146972656,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.2816087603569031,0,127.31857299804688,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.24878376722335815,0,-13.31737232208252,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.7774905562400818,0,1062.77490234375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.6576719880104065,0,673.6580200195312,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.48674502968788147,0,374.6397399902344,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.31582415103912354,0,172.26116943359375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.205200657248497,0,39.636436462402344,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.16572052240371704,0,-60.20586395263672,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,1.3024816513061523,0,1598.83251953125,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.9938627481460571,0,995.7991943359375,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.6220873594284058,0,596.5234375,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.41505134105682373,0,335.4264221191406,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.2847457230091095,0,157.8206329345703,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.2396436631679535,0,16.29468536376953,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g clip-path="url(#__lottie_element_376)" transform="matrix(1,0,0,1.0015000104904175,0,0)" opacity="1" style={{ display: "block" }}>
                <g clip-path="url(#__lottie_element_377)">
                  <g transform="matrix(7.322150230407715,0,0,1.3007700443267822,9823.9833984375,1285.16552734375)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.4902619421482086,0,518.1962280273438,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.4082224667072296,0,286.6288146972656,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.2816087603569031,0,127.31857299804688,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,0.33458998799324036,-0.24878376722335815,0,-13.31737232208252,937.1737060546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-561.8809814453125,-281.86700439453125)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M-281.94500732421875,282.2749938964844 C-281.94500732421875,282.2749938964844 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 285.0379943847656,58.29499816894531 125.72000122070312,-105.8949966430664 C-65.64299774169922,-303.1099853515625 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 -281.94500732421875,282.2749938964844 -281.94500732421875,282.2749938964844z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(6.026879787445068,0,0,2.19963002204895,8086.14453125,1401.3768310546875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.7774905562400818,0,1062.77490234375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.6576719880104065,0,673.6580200195312,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.48674502968788147,0,374.6397399902344,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.31582415103912354,0,172.26116943359375,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.205200657248497,0,39.636436462402344,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5645800232887268,0.16572052240371704,0,-60.20586395263672,373.93853759765625)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(7.2560601234436035,0,0,2.311350107192993,9735.3115234375,1120.3367919921875)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-1205.511962890625,-411.9800109863281)">
                      <path
                        fill="rgb(35,31,32)"
                        fill-opacity="1"
                        d=" M136.16799926757812,-72.73049926757812 C136.16799926757812,-72.73049926757812 136.16799926757812,72.73049926757812 136.16799926757812,72.73049926757812 C136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 -136.16799926757812,72.73049926757812 C-136.16799926757812,72.73049926757812 -136.16799926757812,-72.73049926757812 -136.16799926757812,-72.73049926757812 C-136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812 136.16799926757812,-72.73049926757812z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,1.3024816513061523,0,1598.83251953125,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.9938627481460571,0,995.7991943359375,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.6220873594284058,0,596.5234375,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.41505134105682373,0,335.4264221191406,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.2847457230091095,0,157.8206329345703,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                  <g transform="matrix(0,-0.5911399722099304,0.2396436631679535,0,16.29468536376953,40.56934356689453)" opacity="1" style={{ display: "block" }}>
                    <g opacity="1" transform="matrix(1,0,0,1,-213.82400512695312,154.18699645996094)">
                      <path
                        fill="rgb(242,239,233)"
                        fill-opacity="1"
                        d=" M282.4530029296875,-282.4530029296875 C282.4530029296875,-282.4530029296875 282.4530029296875,282.4530029296875 282.4530029296875,282.4530029296875 C282.4530029296875,282.4530029296875 282.04901123046875,64.17900085449219 122.73100280761719,-100.01100158691406 C-68.63200378417969,-297.22601318359375 -282.4530029296875,-282.4530029296875 -282.4530029296875,-282.4530029296875 C-282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875 282.4530029296875,-282.4530029296875z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </main>
    </body>
  );
}

export default App;
