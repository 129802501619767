import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import SearchBar from "../components/SearchBar";
import Services from "../components/Services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faTrash, faHome, faTrademark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "../css/App.css";

function Scrapers() {
  const { user } = UserAuth();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [classes, setClasses] = useState([]);
  const [countries, setCountries] = useState([]);
  const [readyToSearch, setReadyToSearch] = useState(false);
  const [searchOption, setSearchOption] = useState("contains");

  const navigate = useNavigate();

  return (
    <div className="container mx-auto w-full pl-5">
      <div className="text-sm breadcrumbs pb-5">
        <ul>
          <li>
            <Link className="text-secondary" to={`/`}>
              <FontAwesomeIcon icon={faHome} size="1x" />
              <p className="pl-2 text-secondary">Home</p>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/trademarking`}>
              <FontAwesomeIcon icon={faTrademark} size="1x" />
              <p className="pl-2 text-secondary">Trademarking</p>
            </Link>
          </li>
        </ul>
      </div>
      <SearchBar query={query} setQuery={setQuery} classes={classes} setClasses={setClasses} countries={countries} setCountries={setCountries} loading={loading} setReadyToSearch={setReadyToSearch} setSearchOption={setSearchOption} searchOption={searchOption} />
      <Services query={query} countries={countries} classes={classes} readyToSearch={readyToSearch} setLoading={setLoading} searchOption={searchOption} />
    </div>
  );
}

export default Scrapers;
