import Service from "./Service";

const websites = ["tmview"]; //, "google_domain", "twitter", "linkedin"];

const Services = ({ query, countries, classes, readyToSearch, setLoading, searchOption }) => {
  return (
    <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
      {websites.map((d) => (
        <Service key={d} query={query} countries={countries} classes={classes} website={d} readyToSearch={readyToSearch} searchOption={searchOption} />
      ))}
    </div>
  );
};

export default Services;
