export const countryOptions = [
  {
    label: "Europe",
    value: [
      "AL",
      "AT",
      "BA",
      "BG",
      "BX",
      "CH",
      "CY",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FR",
      "GB",
      "GE",
      "GR",
      "HR",
      "HU",
      "IE",
      "IS",
      "IT",
      "LI",
      "LT",
      "LV",
      "MC",
      "MD",
      "ME",
      "MK",
      "MT",
      "NO",
      "PL",
      "PT",
      "RO",
      "RS",
      "RU",
      "SE",
      "SI",
      "SK",
      "SM",
      "EM",
      "WO",
    ],
  },
  {
    label: "🇪🇺: European Union",
    value: ["AT", "BG", "BX", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GR", "HR", "HU", "IE", "IT", "LT", "LV", "MT", "PL", "PT", "RO", "SE", "SI", "SK", "EM", "WO"],
  },
  {
    label: "Non-European Union",
    value: ["AL", "BA", "CH", "GB", "GE", "IS", "LI", "MC", "MD", "ME", "MK", "NO", "RS", "RU", "SM"],
  },
  {
    label: "Africa",
    value: ["AP", "EG", "MA", "OA", "TN", "ZM"],
  },
  {
    label: "Asia",
    value: ["BN", "CN", "IL", "IN", "JO", "JP", "KH", "KR", "LA", "MY", "PH", "TH", "TR"],
  },
  {
    label: "Oceania",
    value: ["AU", "NZ"],
  },
  {
    label: "America",
    value: ["AR", "BR", "CA", "CL", "CO", "CR", "CU", "MX", "PE", "US", "UY"],
  },
  { label: "🇦🇫: Afghanistan", value: "AF" },
  { label: "🇦🇱: Albania", value: "AL" },
  { label: "🇩🇿: Algeria", value: "DZ" },
  { label: "🇦🇩: Andorra", value: "AD" },
  { label: "🇦🇴: Angola", value: "AO" },
  { label: "🇦🇮: Anguilla", value: "AI" },
  { label: "🇦🇶: Antarctica", value: "AQ" },
  { label: "🇦🇬: Antigua and Barbuda", value: "AG" },
  { label: "🇦🇷: Argentina", value: "AR" },
  { label: "🇦🇲: Armenia", value: "AM" },
  { label: "🇦🇼: Aruba", value: "AW" },
  { label: "🇦🇺: Australia", value: "AU" },
  { label: "🇦🇹: Austria", value: "AT" },
  { label: "🇦🇿: Azerbaijan", value: "AZ" },
  { label: "🇧🇸: Bahamas", value: "BS" },
  { label: "🇧🇭: Bahrain", value: "BH" },
  { label: "🇧🇩: Bangladesh", value: "BD" },
  { label: "🇧🇧: Barbados", value: "BB" },
  { label: "🇧🇾: Belarus", value: "BY" },
  { label: "🇧🇪: Belgium", value: "BE" },
  { label: "🇧🇿: Belize", value: "BZ" },
  { label: "🇧🇯: Benin", value: "BJ" },
  { label: "🇧🇲: Bermuda", value: "BM" },
  { label: "🇧🇹: Bhutan", value: "BT" },
  { label: "🇧🇴: Bolivia (Plurinational State of)", value: "BO" },
  { label: "🇧🇦: Bosnia and Herzegovina", value: "BA" },
  { label: "🇧🇼: Botswana", value: "BW" },
  { label: "🇧🇷: Brazil", value: "BR" },
  { label: "🇮🇴: British Indian Ocean Territory", value: "IO" },
  { label: "🇻🇬: Virgin Islands (British)", value: "VG" },
  { label: "🇧🇳: Brunei Darussalam", value: "BN" },
  { label: "🇧🇬: Bulgaria", value: "BG" },
  { label: "🇧🇫: Burkina Faso", value: "BF" },
  { label: "🇧🇮: Burundi", value: "BI" },
  { label: "🇰🇭: Cambodia", value: "KH" },
  { label: "🇨🇲: Cameroon", value: "CM" },
  { label: "🇨🇦: Canada", value: "CA" },
  { label: "🇨🇻: Cape Verde", value: "CV" },
  { label: "🇰🇾: Cayman Islands", value: "KY" },
  { label: "🇨🇫: Central African Republic", value: "CF" },
  { label: "🇹🇩: Chad", value: "TD" },
  { label: "🇨🇱: Chile", value: "CL" },
  { label: "🇨🇳: China", value: "CN" },
  { label: "🇨🇽: Christmas Island", value: "CX" },
  { label: "🇨🇨: Cocos (Keeling) Islands", value: "CC" },
  { label: "🇨🇴: Colombia", value: "CO" },
  { label: "🇰🇲: Comoros", value: "KM" },
  { label: "🇨🇬: Congo", value: "CG" },
  { label: "🇨🇩: Congo (Democratic Republic of the)", value: "CD" },
  { label: "🇨🇰: Cook Islands", value: "CK" },
  { label: "🇨🇷: Costa Rica", value: "CR" },
  { label: "🇭🇷: Croatia", value: "HR" },
  { label: "🇨🇺: Cuba", value: "CU" },
  { label: "🇨🇼: Curaçao", value: "CW" },
  { label: "🇨🇾: Cyprus", value: "CY" },
  { label: "🇨🇿: Czech Republic", value: "CZ" },
  { label: "🇩🇰: Denmark", value: "DK" },
  { label: "🇩🇯: Djibouti", value: "DJ" },
  { label: "🇩🇲: Dominica", value: "DM" },
  { label: "🇩🇴: Dominican Republic", value: "DO" },
  { label: "🇪🇨: Ecuador", value: "EC" },
  { label: "🇪🇬: Egypt", value: "EG" },
  { label: "🇸🇻: El Salvador", value: "SV" },
  { label: "🇬🇶: Equatorial Guinea", value: "GQ" },
  { label: "🇪🇷: Eritrea", value: "ER" },
  { label: "🇪🇪: Estonia", value: "EE" },
  { label: "🇸🇿: Eswatini", value: "SZ" },
  { label: "🇪🇹: Ethiopia", value: "ET" },
  { label: "🇫🇰: Falkland Islands (Malvinas)", value: "FK" },
  { label: "🇫🇴: Faroe Islands", value: "FO" },
  { label: "🇫🇯: Fiji", value: "FJ" },
  { label: "🇫🇮: Finland", value: "FI" },
  { label: "🇫🇷: France", value: "FR" },
  { label: "🇬🇫: French Guiana", value: "GF" },
  { label: "🇵🇫: French Polynesia", value: "PF" },
  { label: "🇹🇫: French Southern Territories", value: "TF" },
  { label: "🇬🇦: Gabon", value: "GA" },
  { label: "🇬🇲: Gambia", value: "GM" },
  { label: "🇬🇪: Georgia", value: "GE" },
  { label: "🇩🇪: Germany", value: "DE" },
  { label: "🇬🇭: Ghana", value: "GH" },
  { label: "🇬🇮: Gibraltar", value: "GI" },
  { label: "🇬🇷: Greece", value: "GR" },
  { label: "🇬🇱: Greenland", value: "GL" },
  { label: "🇬🇩: Grenada", value: "GD" },
  { label: "🇬🇵: Guadeloupe", value: "GP" },
  { label: "🇬🇺: Guam", value: "GU" },
  { label: "🇬🇹: Guatemala", value: "GT" },
  { label: "🇬🇬: Guernsey", value: "GG" },
  { label: "🇬🇳: Guinea", value: "GN" },
  { label: "🇬🇼: Guinea-Bissau", value: "GW" },
  { label: "🇬🇾: Guyana", value: "GY" },
  { label: "🇭🇹: Haiti", value: "HT" },
  { label: "🇭🇳: Honduras", value: "HN" },
  { label: "🇭🇰: Hong Kong", value: "HK" },
  { label: "🇭🇺: Hungary", value: "HU" },
  { label: "🇮🇸: Iceland", value: "IS" },
  { label: "🇮🇳: India", value: "IN" },
  { label: "🇮🇩: Indonesia", value: "ID" },
  { label: "🇮🇷: Iran (Islamic Republic of)", value: "IR" },
  { label: "🇮🇶: Iraq", value: "IQ" },
  { label: "🇮🇪: Ireland", value: "IE" },
  { label: "🇮🇲: Isle of Man", value: "IM" },
  { label: "🇮🇱: Israel", value: "IL" },
  { label: "🇮🇹: Italy", value: "IT" },
  { label: "🇯🇲: Jamaica", value: "JM" },
  { label: "🇯🇵: Japan", value: "JP" },
  { label: "🇯🇪: Jersey", value: "JE" },
  { label: "🇯🇴: Jordan", value: "JO" },
  { label: "🇰🇿: Kazakhstan", value: "KZ" },
  { label: "🇰🇪: Kenya", value: "KE" },
  { label: "🇰🇮: Kiribati", value: "KI" },
  { label: "🇰🇵: Korea (Democratic People's Republic of)", value: "KP" },
  { label: "🇰🇷: Korea, Republic of", value: "KR" },
  { label: "🇽🇰: Kosovo", value: "XK" },
  { label: "🇰🇼: Kuwait", value: "KW" },
  { label: "🇰🇬: Kyrgyzstan", value: "KG" },
  { label: "🇱🇦: Lao People's Democratic Republic", value: "LA" },
  { label: "🇱🇻: Latvia", value: "LV" },
  { label: "🇱🇧: Lebanon", value: "LB" },
  { label: "🇱🇸: Lesotho", value: "LS" },
  { label: "🇱🇷: Liberia", value: "LR" },
  { label: "🇱🇾: Libya", value: "LY" },
  { label: "🇱🇮: Liechtenstein", value: "LI" },
  { label: "🇱🇹: Lithuania", value: "LT" },
  { label: "🇱🇺: Luxembourg", value: "LU" },
  { label: "🇲🇴: Macao", value: "MO" },
  { label: "🇲🇰: North Macedonia", value: "MK" },
  { label: "🇲🇬: Madagascar", value: "MG" },
  { label: "🇲🇼: Malawi", value: "MW" },
  { label: "🇲🇾: Malaysia", value: "MY" },
  { label: "🇲🇻: Maldives", value: "MV" },
  { label: "🇲🇱: Mali", value: "ML" },
  { label: "🇲🇹: Malta", value: "MT" },
  { label: "🇲🇭: Marshall Islands", value: "MH" },
  { label: "🇲🇶: Martinique", value: "MQ" },
  { label: "🇲🇷: Mauritania", value: "MR" },
  { label: "🇲🇺: Mauritius", value: "MU" },
  { label: "🇾🇹: Mayotte", value: "YT" },
  { label: "🇲🇽: Mexico", value: "MX" },
  { label: "🇫🇲: Micronesia (Federated States of)", value: "FM" },
  { label: "🇲🇩: Moldova, Republic of", value: "MD" },
  { label: "🇲🇨: Monaco", value: "MC" },
  { label: "🇲🇳: Mongolia", value: "MN" },
  { label: "🇲🇪: Montenegro", value: "ME" },
  { label: "🇲🇸: Montserrat", value: "MS" },
  { label: "🇲🇦: Morocco", value: "MA" },
  { label: "🇲🇿: Mozambique", value: "MZ" },
  { label: "🇲🇲: Myanmar", value: "MM" },
  { label: "🇳🇦: Namibia", value: "NA" },
  { label: "🇳🇷: Nauru", value: "NR" },
  { label: "🇳🇵: Nepal", value: "NP" },
  { label: "🇳🇱: Netherlands", value: "NL" },
  { label: "🇳🇨: New Caledonia", value: "NC" },
  { label: "🇳🇿: New Zealand", value: "NZ" },
  { label: "🇳🇮: Nicaragua", value: "NI" },
  { label: "🇳🇪: Niger", value: "NE" },
  { label: "🇳🇬: Nigeria", value: "NG" },
  { label: "🇳🇺: Niue", value: "NU" },
  { label: "🇳🇫: Norfolk Island", value: "NF" },
  { label: "🇲🇵: Northern Mariana Islands", value: "MP" },
  { label: "🇳🇴: Norway", value: "NO" },
  { label: "🇴🇲: Oman", value: "OM" },
  { label: "🇵🇰: Pakistan", value: "PK" },
  { label: "🇵🇼: Palau", value: "PW" },
  { label: "🇵🇸: Palestine, State of", value: "PS" },
  { label: "🇵🇦: Panama", value: "PA" },
  { label: "🇵🇬: Papua New Guinea", value: "PG" },
  { label: "🇵🇾: Paraguay", value: "PY" },
  { label: "🇵🇪: Peru", value: "PE" },
  { label: "🇵🇭: Philippines", value: "PH" },
  { label: "🇵🇳: Pitcairn", value: "PN" },
  { label: "🇵🇱: Poland", value: "PL" },
  { label: "🇵🇹: Portugal", value: "PT" },
  { label: "🇵🇷: Puerto Rico", value: "PR" },
  { label: "🇶🇦: Qatar", value: "QA" },
  { label: "🇷🇪: Réunion", value: "RE" },
  { label: "🇷🇴: Romania", value: "RO" },
  { label: "🇷🇺: Russian Federation", value: "RU" },
  { label: "🇷🇼: Rwanda", value: "RW" },
  { label: "🇧🇱: Saint Barthélemy", value: "BL" },
  { label: "🇸🇭: Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
  { label: "🇰🇳: Saint Kitts and Nevis", value: "KN" },
  { label: "🇱🇨: Saint Lucia", value: "LC" },
  { label: "🇲🇫: Saint Martin (French part)", value: "MF" },
  { label: "🇵🇲: Saint Pierre and Miquelon", value: "PM" },
  { label: "🇻🇨: Saint Vincent and the Grenadines", value: "VC" },
  { label: "🇼🇸: Samoa", value: "WS" },
  { label: "🇸🇲: San Marino", value: "SM" },
  { label: "🇸🇹: Sao Tome and Principe", value: "ST" },
  { label: "🇸🇦: Saudi Arabia", value: "SA" },
  { label: "🇸🇳: Senegal", value: "SN" },
  { label: "🇷🇸: Serbia", value: "RS" },
  { label: "🇸🇨: Seychelles", value: "SC" },
  { label: "🇸🇱: Sierra Leone", value: "SL" },
  { label: "🇸🇬: Singapore", value: "SG" },
  { label: "🇸🇽: Sint Maarten (Dutch part)", value: "SX" },
  { label: "🇸🇰: Slovakia", value: "SK" },
  { label: "🇸🇮: Slovenia", value: "SI" },
  { label: "🇸🇧: Solomon Islands", value: "SB" },
  { label: "🇸🇴: Somalia", value: "SO" },
  { label: "🇿🇦: South Africa", value: "ZA" },
  { label: "🇬🇸: South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "🇸🇸: South Sudan", value: "SS" },
  { label: "🇪🇸: Spain", value: "ES" },
  { label: "🇱🇰: Sri Lanka", value: "LK" },
  { label: "🇸🇩: Sudan", value: "SD" },
  { label: "🇸🇷: Suriname", value: "SR" },
  { label: "🇸🇯: Svalbard and Jan Mayen", value: "SJ" },
  { label: "🇸🇿: Eswatini", value: "SZ" },
  { label: "🇸🇪: Sweden", value: "SE" },
  { label: "🇨🇭: Switzerland", value: "CH" },
  { label: "🇸🇾: Syrian Arab Republic", value: "SY" },
  { label: "🇹🇼: Taiwan, Province of China", value: "TW" },
  { label: "🇹🇯: Tajikistan", value: "TJ" },
  { label: "🇹🇿: Tanzania, United Republic of", value: "TZ" },
  { label: "🇹🇭: Thailand", value: "TH" },
  { label: "🇹🇱: Timor-Leste", value: "TL" },
  { label: "🇹🇬: Togo", value: "TG" },
  { label: "🇹🇰: Tokelau", value: "TK" },
  { label: "🇹🇴: Tonga", value: "TO" },
  { label: "🇹🇹: Trinidad and Tobago", value: "TT" },
  { label: "🇹🇳: Tunisia", value: "TN" },
  { label: "🇹🇷: Turkey", value: "TR" },
  { label: "🇹🇲: Turkmenistan", value: "TM" },
  { label: "🇹🇨: Turks and Caicos Islands", value: "TC" },
  { label: "🇹🇻: Tuvalu", value: "TV" },
  { label: "🇺🇬: Uganda", value: "UG" },
  { label: "🇺🇦: Ukraine", value: "UA" },
  { label: "🇦🇪: United Arab Emirates", value: "AE" },
  { label: "🇬🇧: United Kingdom of Great Britain and Northern Ireland", value: "GB" },
  { label: "🇺🇸: United States of America", value: "US" },
  { label: "🇺🇾: Uruguay", value: "UY" },
  { label: "🇺🇿: Uzbekistan", value: "UZ" },
  { label: "🇻🇺: Vanuatu", value: "VU" },
  { label: "🇻🇦: Holy See", value: "VA" },
  { label: "🇻🇪: Venezuela (Bolivarian Republic of)", value: "VE" },
  { label: "🇻🇳: Viet Nam", value: "VN" },
  { label: "🇻🇬: Virgin Islands (British)", value: "VG" },
  { label: "🇻🇮: Virgin Islands (U.S.)", value: "VI" },
  { label: "🇼🇫: Wallis and Futuna", value: "WF" },
  { label: "🇪🇭: Western Sahara", value: "EH" },
  { label: "🇾🇪: Yemen", value: "YE" },
  { label: "🇿🇲: Zambia", value: "ZM" },
  { label: "🇿🇼: Zimbabwe", value: "ZW" },
];

export const classesOptions = [
  {
    label:
      "1: Chemicals for use in industry, science and photography, as well as in agriculture, horticulture and forestry; Unprocessed artificial resins, unprocessed plastics; Fire extinguishing and fire prevention compositions; Tempering and soldering preparations; Substances for tanning animal skins and hides; Adhesives for use in industry; Putties and other paste fillers; Compost, manures, fertilizers; Biological preparations for use in industry and science",
    value: "1",
  },
  {
    label:
      "2: Paints, varnishes, lacquers; Preservatives against rust and against deterioration of wood; Colorants, dyes; Inks for printing, marking and engraving; Raw natural resins; Metals in foil and powder form for use in painting, decorating, printing and art",
    value: "2",
  },
  {
    label:
      "3: Non-medicated cosmetics and toiletry preparations; Non-medicated dentifrices; Perfumery, essential oils; Bleaching preparations and other substances for laundry use; Cleaning, polishing, scouring and abrasive preparations",
    value: "3",
  },
  {
    label: "4: Industrial oils and greases, wax; Lubricants; Dust absorbing, wetting and binding compositions; Fuels and illuminants; Candles and wicks for lighting",
    value: "4",
  },
  {
    label:
      "5: Pharmaceuticals, medical and veterinary preparations; Sanitary preparations for medical purposes; Dietetic food and substances adapted for medical or veterinary use, food for babies; Dietary supplements for human beings and animals; Plasters, materials for dressings; Material for stopping teeth, dental wax; Disinfectants; Preparations for destroying vermin; Fungicides, herbicides",
    value: "5",
  },
  {
    label:
      "6: Common metals and their alloys, ores; Metal materials for building and construction; Transportable buildings of metal; Non-electric cables and wires of common metal; Small items of metal hardware; Metal containers for storage or transport; Safes",
    value: "6",
  },
  {
    label:
      "7: Machines, machine tools, power-operated tools; Motors and engines, except for land vehicles; Machine coupling and transmission components, except for land vehicles; Agricultural implements, other than hand-operated hand tools; Incubators for eggs; Automatic vending machines",
    value: "7",
  },
  { label: "8: Hand tools and implements, hand-operated; Cutlery; Side arms, except firearms; Razors", value: "8" },
  {
    label:
      "9: Scientific, research, navigation, surveying, photographic, cinematographic, audiovisual, optical, weighing, measuring, signalling, detecting, testing, inspecting, life-saving and teaching apparatus and instruments; Apparatus and instruments for conducting, switching, transforming, accumulating, regulating or controlling the distribution or use of electricity; Apparatus and instruments for recording, transmitting, reproducing or processing sound, images or data; Recorded and downloadable media, computer software, blank digital or analogue recording and storage media; Mechanisms for coin-operated apparatus; Cash registers, calculating devices; Computers and computer peripheral devices; Diving suits, divers' masks, ear plugs for divers, nose clips for divers and swimmers, gloves for divers, breathing apparatus for underwater swimming; Fire-extinguishing apparatus",
    value: "9",
  },
  {
    label:
      "10: Surgical, medical, dental and veterinary apparatus and instruments; Artificial limbs, eyes and teeth; Orthopaedic articles; Suture materials; Therapeutic and assistive devices adapted for persons with disabilities; Massage apparatus; Apparatus, devices and articles for nursing infants; Sexual activity apparatus, devices and articles",
    value: "10",
  },
  {
    label:
      "11: Apparatus for lighting, heating, steam generating, cooking, refrigerating, drying, ventilating, water supply and sanitary purposes; Apparatus and installations for lighting, heating, cooling, steam generating, cooking, refrigerating, drying, ventilating, water supply and sanitary purposes",
    value: "11",
  },
  { label: "12: Vehicles; Apparatus for locomotion by land, air or water", value: "12" },
  { label: "13: Firearms; Ammunition and projectiles; Explosives; Fireworks", value: "13" },
  {
    label: "14: Precious metals and their alloys; Jewellery, precious and semi-precious stones; Horological and chronometric instruments",
    value: "14",
  },
  {
    label: "15: Musical instruments; Music stands and stands for musical instruments; Conductors' batons",
    value: "15",
  },
  {
    label:
      "16: Paper, cardboard; Printed matter; Bookbinding material; Photographs; Stationery and office requisites, except furniture; Adhesives for stationery or household purposes; Drawing materials and materials for artists; Paintbrushes; Instructional and teaching materials; Plastic sheets, films and bags for wrapping and packaging; Printers' type, printing blocks",
    value: "16",
  },
  {
    label:
      "17: Raw and partly processed rubber, gutta-percha, gum, asbestos, mica and substitutes for all these materials; Plastics and resins in extruded form for use in manufacture; Packing, stopping and insulating materials; Flexible pipes, tubes and hoses, not of metal",
    value: "17",
  },
  {
    label:
      "18: Leather and imitations of leather; Animal skins and hides; Luggage and carrying bags; Umbrellas and parasols; Walking sticks; Whips, harness and saddlery; Collars, leashes and clothing for animals",
    value: "18",
  },
  {
    label:
      "19: Non-metallic building materials; Non-metallic rigid pipes for building; Asphalt, pitch and bitumen; Non-metallic transportable buildings; Monumental and non-monumental sculptures made of stone, concrete or marble",
    value: "19",
  },
  {
    label:
      "20: Furniture, mirrors, picture frames; Containers, not of metal, for storage or transport; Unworked or semi-worked bone, horn, whalebone or mother-of-pearl; Shells; Meerschaum; Yellow amber",
    value: "20",
  },
  {
    label:
      "21: Household or kitchen utensils and containers; Combs and sponges; Brushes, except paintbrushes; Brush-making materials; Articles for cleaning purposes; Steelwool; Unworked or semi-worked glass, except building glass; Glassware, porcelain and earthenware",
    value: "21",
  },
  {
    label:
      "22: Ropes and string; Nets; Tents and tarpaulins; Awnings of textile or synthetic materials; Sails; Sacks for the transport and storage of materials in bulk; Padding, cushioning and stuffing materials, except of paper, cardboard, rubber or plastics; Raw fibrous textile materials and substitutes therefor",
    value: "22",
  },
  { label: "23: Yarns and threads, for textile use", value: "23" },
  {
    label: "24: Textiles and substitutes for textiles; Bed and table covers; Travelling rugs; Household linen; Curtains of textile or plastic",
    value: "24",
  },
  { label: "25: Clothing, footwear, headgear", value: "25" },
  {
    label: "26: Lace and embroidery; Ribbons and braid; Buttons, hooks and eyes, pins and needles; Artificial flowers; Hair decorations; False hair",
    value: "26",
  },
  {
    label: "27: Carpets, rugs, mats and matting, linoleum and other materials for covering existing floors; Wall hangings (non-textile)",
    value: "27",
  },
  {
    label: "28: Games, toys; Gymnastic and sporting articles not included in other classes; Decorations for Christmas trees",
    value: "28",
  },
  {
    label:
      "29: Meat, fish, poultry and game; Meat extracts; Preserved, frozen, dried and cooked fruits and vegetables; Jellies, jams, compotes; Eggs; Milk and milk products; Edible oils and fats; Prepared meals and dishes; Soups, bouillons; Preserved, dried and cooked nuts and seeds; Snack foods; Milk beverages containing fruits; Milk beverages containing coffee; Milk-based beverages; Protein milk beverages; Whey beverages; Yogurt beverages; Fruit beverages and fruit juices; Vegetable beverages; Vegetable juices",
    value: "29",
  },
  {
    label:
      "30: Coffee, tea, cocoa and artificial coffee; Rice; Tapioca and sago; Flour and preparations made from cereals; Bread, pastries, cakes and biscuits; Confectionery; Edible ices; Sugar, honey, treacle; Yeast, baking-powder; Salt; Mustard; Vinegar, sauces (condiments); Spices; Ice",
    value: "30",
  },
  {
    label:
      "31: Raw and unprocessed agricultural, aquacultural, horticultural and forestry products; Raw and unprocessed grains and seeds; Fresh fruits and vegetables; Fresh herbs; Natural plants and flowers; Bulbs, seedlings and seeds for planting; Live animals; Foodstuffs and beverages for animals; Animal litter",
    value: "31",
  },
  {
    label: "32: Beers; Mineral and aerated waters and other non-alcoholic beverages; Fruit beverages and fruit juices; Syrups and other preparations for making beverages",
    value: "32",
  },
  { label: "33: Alcoholic beverages (except beers); Alcoholic preparations for making beverages", value: "33" },
  { label: "34: Tobacco; Smokers' articles; Matches", value: "34" },
  { label: "35: Advertising; Business management; Business administration; Office functions", value: "35" },
  { label: "36: Insurance; Financial affairs; Monetary affairs; Real estate affairs", value: "36" },
  { label: "37: Building construction; Repair; Installation services", value: "37" },
  { label: "38: Telecommunications", value: "38" },
  { label: "39: Transport; Packaging and storage of goods; Travel arrangement", value: "39" },
  {
    label: "40: Treatment of materials; Recycling of waste and trash; Incineration services; Custom manufacturing services",
    value: "40",
  },
  { label: "41: Education; Providing of training; Entertainment; Sporting and cultural activities", value: "41" },
  {
    label:
      "42: Scientific and technological services and research and design relating thereto; Industrial analysis and research services; Design and development of computer hardware and software; Legal services",
    value: "42",
  },
  {
    label: "43: Services for providing food and drink; Temporary accommodation; Restaurant and hotel services; Catering services; Bars; Cafés; Cafeterias",
    value: "43",
  },
  {
    label: "44: Medical services; Veterinary services; Hygienic and beauty care for human beings or animals; Agriculture, aquaculture, horticulture and forestry services",
    value: "44",
  },
  {
    label:
      "45: Personal and social services rendered by others to meet the needs of individuals; Security services for the protection of property and individuals; Personal and social services rendered by others to safeguard personal property",
    value: "45",
  },
];
