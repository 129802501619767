import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const PrivateRoute = ({ element }) => {
  const { user } = UserAuth();
  console.log("PrivateRoute", user);
  return user ? element : <Navigate to="/signin" />;
};

export default PrivateRoute;
