import { useEffect, useState } from "react";
import mappingIcons from "../scripts/mappingIcons";
import ServiceDecision from "./ServiceDecision";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark, faRedo, faChevronRight, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { classesOptions } from "../variables/countryOptions";

function Service({ website, query, countries, classes, readyToSearch, searchOption }) {
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [apiStatus, setApiStatus] = useState("");
  const [decision, setDecision] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  const host = process.env.REACT_APP_SERVER;

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const retryApiCall = () => {
    // setLoading(true);
    // setApiStatus("");
  };

  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getCountryFlagEmoji = (countryCode) => {
    const OFFSET = 127397;
    const codePoints = countryCode
      .toUpperCase()
      .split("")
      .map((char) => char.charCodeAt() + OFFSET);

    return String.fromCodePoint(...codePoints);
  };

  // useEffect(() => {
  //   if (loading === false && Object.keys(data).length === 0 && apiStatus === "") {
  //     return;
  //   }

  //   const choices = ["A", "B", "C"];
  //   const randomDecision = choices[Math.floor(Math.random() * choices.length)];
  //   setDecision(randomDecision);
  // }, [loading, data, apiStatus, decision]);

  useEffect(() => {
    if (query !== "" && readyToSearch === true) {
      const url = "https://dgwamab4o3.execute-api.us-east-1.amazonaws.com/test";
      const fetchData = async () => {
        setLoading(true);
        try {
          const params = new URLSearchParams();
          params.append("q", query);

          if (website === "tmview") {
            if (countries.length > 0) {
              params.append("countries", countries.map((d) => d.value).join());
            }
            if (classes.length > 0) {
              params.append("nice_classes", classes.map((d) => Number(d.value)).join());
            }

            if (page) {
              params.append("page", page);
            }

            if (searchOption) {
              params.append("search_type", searchOption);
            }
          }
          const response = await fetch(`${url}/${website}?${params.toString()}`);
          // const response = await fetch(`${host}/api/trademark?${params.toString()}`);
          if (response.ok) {
            let data = await response.json();
            if (website === "google_domain") {
              let sample_results = data.map((d) => ({
                id: d.domain,
                name: d.name,
                available: d.available === "AVAILABILITY_UNAVAILABLE" ? false : true,
              }));
              data = { sample_results };
            }
            setData(data);
            setDecision(data["grade"]);
            console.log('data["grade"]', data["grade"]);
            setApiStatus("success");
          } else {
            setApiStatus("error");
          }
        } catch (error) {
          console.log("error", error);
          setApiStatus("error");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [page, readyToSearch, apiStatus, decision]);

  if (loading === false && Object.keys(data).length === 0 && apiStatus === "") {
    return <></>;
  }

  return (
    <div className="project-card rounded-lg shadow-md p-4 mb-4">
      <div className="grid grid-cols-5 project-header flex items-center justify-between cursor-pointer" onClick={toggleExpansion}>
        <div>{mappingIcons[website]}</div>
        <div>
          <p>{website === "google_domain" ? "website" : website}</p>
        </div>
        <div>
          <ServiceDecision loading={loading} decision={decision} apiStatus={apiStatus} />
        </div>
        <div>
          <p>{data.count}</p>
        </div>
        <div>{apiStatus === "error" ? <FontAwesomeIcon icon={faRedo} className="text-l" onClick={retryApiCall} /> : data.sample_results && data.sample_results.length > 0 && <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} className="text-l" />}</div>
      </div>
      {isExpanded && data.sample_results && data.sample_results.length > 0 && (
        <div className="project-details mt-4">
          <table>
            <thead>
              <tr>
                <td>sim</td>
                <td>name</td>
                <td>link</td>
                <td>countries</td>
                <td></td>

                <td>classes</td>
                <td>availability</td>
              </tr>
            </thead>
            <tbody>
              {data.sample_results.map((d) => (
                <tr key={d.id}>
                  <td>{d.similarity_to_query.toFixed(2)}</td>
                  <td>{d.name}</td>
                  <td>
                    <a href={d.url} target="_blank" rel="noopener noreferrer">
                      {d.id}
                    </a>
                  </td>
                  <td>{d.country_code && <span>{d.country_code}</span>}</td>
                  <td>{d.country_code && <span>{getCountryFlagEmoji(d.country_code)}</span>}</td> {/* Add this line */}
                  <td>
                    {d.classes
                      ?.filter((x) => {
                        return classes.map((d) => Number(d.value)).includes(x);
                      })
                      .map((className) => (
                        <span key={className} className="badge badge-primary mr-2 text-neutral">
                          {className}
                        </span>
                      ))}
                  </td>
                  <td>
                    {d.available !== undefined && d.available === true ? <FontAwesomeIcon icon={faCheckCircle} size="1x" style={{ color: "#50FA7B" }} /> : d.available !== undefined && d.available === false ? <FontAwesomeIcon icon={faCircleXmark} size="1x" style={{ color: "#FF5555" }} /> : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <>
            <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} className="text-l" />
            {website === "tmview" && <FontAwesomeIcon icon={faChevronRight} className="text-l ml-2 cursor-pointer" onClick={nextPage} />}
          </>
        </div>
      )}
    </div>
  );
}

export default Service;
