import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faA, faB, faC, faD, faF, faPlug, faCheckCircle, faEllipsisH, faExclamation, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

function ServiceDecision({ loading, decision, apiStatus }) {
  if (loading) {
    return <FontAwesomeIcon icon={faPlug} size="1x" beatFade />;
  } else {
    if (apiStatus === "error") {
      return <FontAwesomeIcon icon={faPlug} size="1x" style={{ color: "#FF5555" }} />;
    } else if (decision === "A") {
      return <FontAwesomeIcon icon={faA} size="1x" style={{ color: "Green" }} />;
    } else if (decision === "B") {
      return <FontAwesomeIcon icon={faB} size="1x" style={{ color: "Green" }} />;
    } else if (decision === "C") {
      return <FontAwesomeIcon icon={faC} size="1x" style={{ color: "Orange" }} />;
    } else if (decision === "D") {
      return <FontAwesomeIcon icon={faD} size="1x" style={{ color: "Orange" }} />;
    } else if (decision === "F") {
      return <FontAwesomeIcon icon={faF} size="1x" style={{ color: "Red" }} />;
    } else {
      return <></>;
    }
  }
}

export default ServiceDecision;
