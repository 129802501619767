import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { countryOptions, classesOptions } from "../variables/countryOptions";

const SearchBar = ({ query, setQuery, classes, setClasses, countries, setCountries, loading, setReadyToSearch, searchOption, setSearchOption }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchButtonClick();
    }
  };

  const handleSearchButtonClick = () => {
    setReadyToSearch(true);
  };

  const handleInputChange = (e) => {
    setReadyToSearch(false);
    setQuery(e.target.value);
    if (e.target.value.length === 0) {
      setIsButtonDisabled(true);
      // setReadyToSearch(false);
    } else {
      setIsButtonDisabled(false);
    }
  };

  const handleSearchOptionChange = (e) => {
    setSearchOption(e.target.value);
  };
  const industryValueRenderer = (selected, _options) => {
    console.log("selected", selected);
    return selected.length ? selected.map(({ value, label }) => `${label.split(",")[0].trim()} `) : "Select all industries of interest";
  };

  const countryValueRenderer = (selected, _options) => {
    console.log("selected", selected);
    return selected.length ? selected.map(({ value, label }) => `${label.split(",")[0].trim()} `) : "Select all countries of interest";
  };

  return (
    <div className="flex flex-col items-center gap-4 p-5">
      <div className="w-full ">
        <div className=" mb-0 text-center pb-5">
          <input type="text" placeholder="Availability Search" className="input input-bordered input-secondary w-full max-w-xs" value={query} onChange={handleInputChange} onKeyPress={handleKeyPress} />
        </div>

        <MultiSelect valueRenderer={countryValueRenderer} options={countryOptions} value={countries} onChange={setCountries} labelledBy={"In which countries do you require trademark protection? Select all that apply"} isCreatable={false} hasSelectAll={false} className="pb-5 dark text-sm" />
        <MultiSelect valueRenderer={industryValueRenderer} options={classesOptions} value={classes} onChange={setClasses} labelledBy={"Select Nice Classes"} isCreatable={false} hasSelectAll={false} className="pb-5 dark text-sm" />
        <div className="flex items-center mt-4">
          <span className="mr-2">Search Option:</span>
          <label className="inline-flex items-center">
            <input type="radio" value="contains" checked={searchOption === "contains"} onChange={handleSearchOptionChange} className="form-radio" />
            <span className="ml-2">Contains</span>
          </label>
          <label className="inline-flex items-center ml-4">
            <input type="radio" value="exact" checked={searchOption === "exact"} onChange={handleSearchOptionChange} className="form-radio" />
            <span className="ml-2">Exact</span>
          </label>
          <label className="inline-flex items-center ml-4">
            <input type="radio" value="fuzzy" checked={searchOption === "fuzzy"} onChange={handleSearchOptionChange} className="form-radio" />
            <span className="ml-2">Fuzzy</span>
          </label>
        </div>
      </div>
      {/* <button type="submit" className="btn btn-secondary mt-5 remove-pill text-neutral "> */}

      <button className="btn btn-secondary ml-2 text-neutral" onClick={handleSearchButtonClick}>
        {loading ? (
          <span className="animate-spin">
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
          </span>
        ) : (
          <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
          </svg>
        )}
      </button>
    </div>
  );
};

export default SearchBar;
