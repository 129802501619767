import React from "react";

function NotFoundPage() {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md">
        <h1 className="">404</h1>
        <p className="text-lg mb-4">Oops! Page not found.</p>
        <p className="text-gray-600">The requested page could not be found.</p>
      </div>
    </div>
  );
}

export default NotFoundPage;
