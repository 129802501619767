import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faMicrochip } from "@fortawesome/free-solid-svg-icons";

import { MultiSelect } from "react-multi-select-component";
import { countryOptions, classesOptions } from "../variables/countryOptions";

function Process() {
  const { processName } = useParams();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [result, setResult] = useState(null);
  const [trademarkData, setTrademarkData] = useState(null);
  const [process_, setProcess] = useState([]);
  const [category, setCategory] = useState("");
  const [n, setN] = useState(100); // State to store the 'n' value from the user
  const [threshold, setThreshold] = useState(0); // State to store the 'threshold' value from the user
  const [topics, setTopics] = useState(""); // State to store the 'topics' value from the user
  const [inputValues, setInputValues] = useState({});
  const [inputValueDifferentiator, setInputValueDifferentiator] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [differentiators, setDifferentiators] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [nice_classes, setNiceClasses] = useState([]);
  const [countries, setCountries] = useState([]);

  const host = process.env.REACT_APP_SERVER;

  const countryValueRenderer = (selected, _options) => {
    console.log("selected", selected);
    return selected.length ? selected.map(({ value, label }) => `${label.split(",")[0].trim()} `) : "Select all countries of interest";
  };

  const industryValueRenderer = (selected, _options) => {
    console.log("selected", selected);
    return selected.length ? selected.map(({ value, label }) => `${label.split(",")[0].trim()} `) : "Select all industries of interest";
  };

  const handleAddDifferentiator = (e) => {
    e.preventDefault(); // Prevent form submission
    if (inputValueDifferentiator && !differentiators.includes(inputValueDifferentiator)) {
      setDifferentiators([...differentiators, inputValueDifferentiator]);
      setInputValueDifferentiator(""); // Clear input field after adding
    }
  };

  const handleRemoveAttribute = (attribute) => {
    setAttributes(attributes.filter((d) => d !== attribute));
  };

  useEffect(() => {
    setLoading(true);
    const fetchNameData = async () => {
      try {
        const response = await fetch(`${host}/api/processes/${processName}`);
        const data = await response.json();
        console.log("data", data);
        console.log("data.data.inputs", data.data.inputs);
        const inputs = data.data.inputs;

        setProcess({ ...data.data });
        setInputs(inputs);

        // setProcess(data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchNameData();
  }, [processName]);

  const handleInputChange = (inputName, newValue) => {
    setInputValues({ ...inputValues, [inputName]: newValue });
  };

  const handleCreate = async () => {
    setLoading(true);
    setStartTime(Date.now()); // Set start time before the process starts

    try {
      // Spread the dynamic inputValues state into your request data
      const requestData = { ...process_, ...inputValues };

      if (countries && countries.length > 0) {
        requestData.countries = countries.map((d) => d.value);
      }

      if (nice_classes && nice_classes.length > 0) {
        requestData.nice_classes = nice_classes.map((d) => d.value);
      }

      if (inputs.some((input) => input.name === "differentiators")) {
        requestData.differentiators = differentiators;
      } else if (inputs.some((input) => input.name === "attributes")) {
        requestData.attributes = differentiators;
      }

      const response = await fetch(`${host}/api/process`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
        const result = JSON.parse(data.message);
        console.log("result", result);
        if (Object.keys(result).includes("nice_classes")) {
          setTrademarkData(result);
        } else {
          setResult(result);
        }
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error sending API request:", error);
    } finally {
      setEndTime(Date.now()); // Set end time after the process completes
      setLoading(false);
    }
  };

  console.log("process_", process_);

  console.log("inputs", inputs, inputs.length > 0);
  console.log("inputValues", inputValues, inputValues.length > 0);

  return (
    <div className="container mx-auto w-full pl-5">
      <div className="text-sm breadcrumbs pb-5">
        <ul>
          <li>
            <Link className="text-secondary" to={`/`}>
              <FontAwesomeIcon icon={faHome} size="1x" />
              <p className="pl-2 text-secondary ">Home</p>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/processes`}>
              <FontAwesomeIcon icon={faMicrochip} size="1x" />
              <p className="pl-2 text-secondary ">Processes</p>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/processes/${processName}`}>
              <p className="pl-2 text-secondary ">{processName}</p>
            </Link>
          </li>
        </ul>
      </div>

      <article className="prose ">
        <h1 className="text-2xl font-semibold">{process_.name}</h1>
        <blockquote className="italic border-l-4 border-secondary-500 pl-4 py-2 mb-4">
          {/* Description */}
          <p>{process_.description}</p>
          {/* <p>{process_.example}</p> */}
        </blockquote>
      </article>

      {process_.generated_names ? (
        <div>
          <article className="prose ">
            <h3 className="text-2xl font-semibold">Previously generated names</h3>
          </article>
          <div className="flex flex-col gap-5 py-5">
            <ul>
              {process_.generated_names.slice(0, 5).map((name, index) => (
                <li key={index}>* {name}</li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}
      {process_.resources ? (
        <div>
          <article className="prose ">
            <h3 className="text-2xl font-semibold">Resources</h3>
          </article>
          <div className="flex flex-col gap-5 py-5">
            <ul>
              {process_.resources.map((name, index) => (
                <li key={index}>* {name}</li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}
      {process_.example ? (
        <div>
          <article className="prose ">
            <h3 className="text-2xl font-semibold">Example</h3>
          </article>
          <div className="flex flex-col gap-5 py-5">{process_.example}</div>
        </div>
      ) : (
        <></>
      )}
      {process_.prompt ? (
        <div>
          <article className="prose ">
            <h3 className="text-2xl font-semibold">Prompt</h3>
          </article>
          <div className="flex flex-col gap-5 py-5">{process_.prompt}</div>
        </div>
      ) : (
        <></>
      )}

      <div className="divider" />
      <article className="prose ">
        <h3 className="text-2xl font-semibold">Inputs</h3>
      </article>
      {inputs &&
        inputs.map((input) => (
          <div key={input.id} className="flex flex-col gap-5 py-5">
            <label>{input.name}</label>
            {input.input_type === "text_area" ? (
              <textarea id={input.name} placeholder={input.example.replace('"', "")} className="textarea input input-bordered input-secondary w-full" value={inputValues[input.name] || ""} onChange={(e) => handleInputChange(input.name, e.target.value)} />
            ) : input.input_type === "multi-select" ? (
              <MultiSelect
                valueRenderer={input.name === "countries" ? countryValueRenderer : industryValueRenderer}
                options={input.name === "countries" ? countryOptions : classesOptions}
                value={input.name === "countries" ? countries : nice_classes}
                onChange={input.name === "countries" ? setCountries : setNiceClasses}
                labelledBy={"In which countries do you require trademark protection? Select all that apply"}
                isCreatable={false}
                hasSelectAll={false}
                className="pb-5 dark text-sm"
              />
            ) : input.input_type === "number_input" ? (
              <input id={input.name} type="number" placeholder={input.example} className="input input-bordered input-secondary w-full" value={inputValues[input.name] || ""} onChange={(e) => handleInputChange(input.name, e.target.value)} />
            ) : input.input_type === "list" ? (
              <div>
                <form onSubmit={handleAddDifferentiator} className="">
                  <input
                    type="text"
                    value={inputValueDifferentiator}
                    onChange={(e) => setInputValueDifferentiator(e.target.value)}
                    placeholder="Being as succinct as possible, in what way(s) is your product or service different and/or better than alternatives? E.g. Safer, more durable, environmentally friendly"
                    className="textarea textarea-primary w-full"
                  />
                  <button type="submit" className="btn btn-primary mt-5 remove-pill">
                    Add
                  </button>
                </form>
                <div className="attribute-pills">
                  {differentiators.map((attribute, index) => (
                    <span key={index} className="pill badge badge-primary mr-5 badge-outline" onClick={() => handleRemoveAttribute(attribute)}>
                      {attribute} x
                    </span>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        ))}

      <div className="flex justify-center">
        <button className={`btn btn-secondary text-neutral`} onClick={handleCreate} disabled={Object.keys(inputValues).length == 0}>
          {loading ? "..." : "TEST"}
        </button>
      </div>

      <div>
        {startTime && endTime && (
          <div>
            <p>Process took: {((endTime - startTime) / 1000).toFixed(2)} seconds</p>
          </div>
        )}
        <div className="divider" />

        {/* Display the API results as a list */}
        {trademarkData && (
          <div>
            <article className="prose ">
              <h3 className="text-2xl font-semibold">Results</h3>
            </article>
            <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
              <h1>{trademarkData.name}</h1>
              <div>
                <strong>Grade:</strong> {trademarkData.grade}
              </div>
              <div>
                <strong>Criteria:</strong> {trademarkData.criteria}
              </div>
              <div>
                <strong>Countries:</strong> {trademarkData.countries?.join(", ")}
              </div>
              <div>
                <strong>Nice Classes:</strong> {trademarkData.nice_classes?.join(", ")}
              </div>
              <div>
                <strong>Trademark Status:</strong> {trademarkData.tm_status?.join(", ")}
              </div>
              <div>
                <strong>Results Count:</strong> {trademarkData.count}
              </div>
              <div>
                <strong>URL:</strong>{" "}
                <Link className="text-secondary text-accent" to={trademarkData.url}>
                  View Trademarks
                </Link>
              </div>
            </div>
          </div>
        )}
        {result && (
          <div>
            <article className="prose ">
              <h3 className="text-2xl font-semibold">Results</h3>
            </article>

            <div className="table-container">
              <table className="table table-xs table-zebra">
                <thead>
                  <tr>
                    <th className="sticky top-0 px-2 py-1 cursor-pointer">Name </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(result) ? (
                    result.map((name) => (
                      <tr key={name.id}>
                        <td className="border px-2 py-1 truncate">{name}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="border px-2 py-1">{JSON.stringify(result)}</td>
                    </tr>
                  )}
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Process;
