import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faTrash, faHome, faMicrochip } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../css/App.css";

function Processes() {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [processes, setProcesses] = useState([]);

  const host = process.env.REACT_APP_SERVER;

  const groupedProcesses = {};

  processes.forEach((d) => {
    if (!groupedProcesses[d.type]) {
      groupedProcesses[d.type] = [];
    }
    groupedProcesses[d.type].push(d);
  });

  useEffect(() => {
    if (user === null) {
      navigate("/signin");
    }
  }, [user, navigate]);

  useEffect(() => {
    setLoading(true);
    const fetchNameData = async () => {
      try {
        const response = await fetch(`${host}/api/processes`);
        const processesData = await response.json();
        console.log(processesData);
        setProcesses(processesData.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchNameData();
  }, []);

  if (!user?.email) {
    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <div className="container mx-auto w-full pl-5">
      <div className="text-sm breadcrumbs pb-5">
        <ul>
          <li>
            <Link className="text-secondary" to={`/`}>
              <FontAwesomeIcon icon={faHome} size="1x" />
              <p className="pl-2 text-secondary">Home</p>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/processes`}>
              <FontAwesomeIcon icon={faMicrochip} size="1x" />
              <p className="pl-2 text-secondary">Processes</p>
            </Link>
          </li>
        </ul>
      </div>
      <div>
        {Object.keys(groupedProcesses).map((type) => (
          <article key={type} className="prose pb-10">
            <h2>{type}</h2>
            <ul>
              {groupedProcesses[type].map((d) => (
                <li key={d.name}>
                  <Link className="text-secondary" to={`/processes/${d.function}`}>
                    {d.name}
                  </Link>
                </li>
              ))}
            </ul>
          </article>
        ))}
      </div>
      {/* <ul>
        {processes.map((d) => (
          <li key={d.name}>
            <Link className="text-secondary"  to={`/processes/${d.function}`}>{d.name}</Link>
          </li>
        ))}
      </ul> */}
    </div>
  );
}

export default Processes;
