import { UserAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";

const Navbar = () => {
  const { user, logOut } = UserAuth();

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };

  // Define the custom avatar URLs based on the user's display name
  const getCustomAvatar = (displayName) => {
    const avatarURLs = {
      "Chris Daly": "https://ca.slack-edge.com/T05A5R2RSD6-U059D6DLDJ9-25c0116d4c68-512", //"https://ca.slack-edge.com/T05A5R2RSD6-U05A5R9T2HE-e311a666d597-512",
      "Jack Wimmer": "https://ca.slack-edge.com/T05A5R2RSD6-U059D6DLDJ9-25c0116d4c68-512",
      "Mark Liney": "https://ca.slack-edge.com/T05A5R2RSD6-U0599FVSH2A-bcc951407291-192",
      "Eden Goonesekera": "https://ca.slack-edge.com/T05A5R2RSD6-U059JK4HUVA-707968a81ad9-512",
      "Lucas Galan": "https://ca.slack-edge.com/T05A5R2RSD6-U0599G2HB62-2dd1c3f2abc0-512",
      // Add more mappings for other users if needed
    };
    return avatarURLs[displayName] || user.photoURL || "/default-avatar.jpg"; // Use user's photoURL if available, or fallback to default avatar
  };

  return (
    <div className="container mx-auto text-center p-5">
      <div className="flex justify-between w-full">
        <Link to={`/`}>
          <img src="/logo.svg" alt="Monika Logo" className="mx-auto w-48 h-auto -ml-1" />
        </Link>
        {user?.displayName && (
          <div className="flex items-center">
            <img src={getCustomAvatar(user.displayName)} alt="Custom Avatar" className="w-10 h-10 rounded-full mr-2" onClick={handleSignOut} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
