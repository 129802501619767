import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./pages/Router";
import "./css/index.css";

  
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<Router></Router>
	</React.StrictMode>
);
