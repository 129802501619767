import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthContextProvider, userAuth } from "../context/AuthContext";
import Navbar from "../components/Navbar";
import App from "./App";
import Scrapers from "./Scrapers";
import ServicePage from "./ServicePage";
import StatsPage from "./StatsPage";
import Projects from "./Projects";
import Processes from "./Processes";
import Project from "./Project";
import Process from "./Process";
import Visual from "./Visual";
import Name from "./Name";
import Form from "./Form";
import SignIn from "./SignIn";
import NotFoundPage from "./NotFoundPage";
import DynamicIcon from "../components/DynamicIcon";
import PrivateRoute from "../components/PrivateRoute"; // Import the PrivateRoute component

const Router = () => (
  <BrowserRouter>
    <AuthContextProvider>
      <DynamicIcon /> {/* Add DynamicIcon here */}
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", paddingBottom: "30px" }}>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/signin" element={<SignInWithNavbar />} />
          <Route path="/app" element={<PrivateRoute element={<WithNavbar element={<App />} />} />} />
          <Route path="/form" element={<PrivateRoute element={<WithNavbar element={<Form />} />} />} />
          <Route path="/visual" element={<PrivateRoute element={<WithNavbar element={<Visual />} />} />} />
          <Route path="/trademarking" element={<PrivateRoute element={<WithNavbar element={<Scrapers />} />} />} />
          <Route path="/projects" element={<PrivateRoute element={<WithNavbar element={<Projects />} />} />} />
          <Route path="/processes" element={<PrivateRoute element={<WithNavbar element={<Processes />} />} />} />
          <Route path="/scrapers/service" element={<PrivateRoute element={<WithNavbar element={<ServicePage />} />} />} />
          <Route path="/404" element={<WithNavbar element={<NotFoundPage />} />} />
          <Route path="/projects/:projectId" element={<PrivateRoute element={<WithNavbar element={<Project />} />} />} />
          <Route path="/projects/:projectId/names/:nameId" element={<PrivateRoute element={<WithNavbar element={<Name />} />} />} />
          <Route path="/projects/:projectId/stats" element={<PrivateRoute element={<WithNavbar element={<StatsPage />} />} />} />
          <Route path="/processes/:processName" element={<PrivateRoute element={<WithNavbar element={<Process />} />} />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <Footer />
    </AuthContextProvider>
  </BrowserRouter>
);

const SignInWithNavbar = () => (
  <>
    <SignIn />
  </>
);

const WithNavbar = ({ element }) => (
  <>
    <Navbar />
    <div style={{ flex: 1 }}>{element}</div>
  </>
);

const Footer = () => (
  <footer className="footer footer-center pt-2 bg-accent text-neutral" style={{ position: "fixed", bottom: 0, width: "100%" }}>
    <aside>
      <p className="footer-title">{process.env.REACT_APP_ENV}</p>
    </aside>
  </footer>
);

export default Router;
