import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrademark } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebookF, faInstagram, faLinkedin, faYoutube, faPinterest, faTiktok, faReddit, faGoogle } from "@fortawesome/free-brands-svg-icons";

let mappingIcons = {};

let prop = "twitter";
mappingIcons[prop] = <FontAwesomeIcon icon={faTwitter} size="1x" />;

prop = "tmview";
mappingIcons[prop] = <FontAwesomeIcon icon={faTrademark} size="1x" />;

prop = "facebook";
mappingIcons[prop] = <FontAwesomeIcon icon={faFacebookF} size="1x" />;

prop = "instagram";
mappingIcons[prop] = <FontAwesomeIcon icon={faInstagram} size="1x" />;

prop = "linkedin";
mappingIcons[prop] = <FontAwesomeIcon icon={faLinkedin} size="1x" />;

prop = "youtube";
mappingIcons[prop] = <FontAwesomeIcon icon={faYoutube} size="1x" />;

prop = "pinterest";
mappingIcons[prop] = <FontAwesomeIcon icon={faPinterest} size="1x" />;

prop = "tiktok";
mappingIcons[prop] = <FontAwesomeIcon icon={faTiktok} size="1x" />;

prop = "reddit";
mappingIcons[prop] = <FontAwesomeIcon icon={faReddit} size="1x" />;

prop = "google_domain";
mappingIcons[prop] = <FontAwesomeIcon icon={faGoogle} size="1x" />;

export default mappingIcons;
