import { useLocation, useNavigate, Link } from "react-router-dom";
// import Results from "../components/Results";

function ServicePage() {
  const { state } = useLocation();
  const { data } = state;
  const { sample_results } = data;

  return (
    <div className="max-w-3xl px-4 mx-auto sm:px-8">
      <div className="py-8">
        <div className="inline-block overflow-hidden rounded-lg shadow">{/* <Results sample_results={sample_results} /> */}</div>
      </div>
    </div>
  );
}

export default ServicePage;
