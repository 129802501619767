import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faTrash, faHome, faFolder, faRefresh, faRunning } from "@fortawesome/free-solid-svg-icons";

function Projects() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const host = process.env.REACT_APP_SERVER;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${host}/api/projects`);
      const jsonData = await response.json();
      jsonData.data.projects.sort((a, b) => b.id - a.id);
      setData(jsonData.data.projects);
      console.log("jsonData.data.projects", jsonData.data.projects);
    } catch (error) {
      // Handle error here, e.g. console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };
  const compareValues = (valueA, valueB) => {
    if (valueA === null) {
      return sortDirection === "asc" ? 1 : -1;
    }
    if (valueB === null) {
      return sortDirection === "asc" ? -1 : 1;
    }

    if (typeof valueA === "string" && typeof valueB === "string") {
      const trademarkOrder = ["A", "B", "C", "D", "F"];
      const indexA = trademarkOrder.indexOf(valueA);
      const indexB = trademarkOrder.indexOf(valueB);

      if (indexA !== -1 && indexB !== -1) {
        return sortDirection === "asc" ? indexA - indexB : indexB - indexA;
      } else if (indexA !== -1) {
        return sortDirection === "asc" ? -1 : 1;
      } else if (indexB !== -1) {
        return sortDirection === "asc" ? 1 : -1;
      } else {
        return sortDirection === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
    } else {
      return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
    }
  };

  const handleDelete = async (projectId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this project?");

    if (confirmDelete) {
      try {
        const response = await fetch(`${host}/api/projects/${projectId}`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          // Project successfully deleted, you can update the UI accordingly
          fetchData(); // Refetch data to update the project list
        } else {
          // Handle error here, e.g., show an error message
          console.error("Failed to delete project");
        }
      } catch (error) {
        // Handle error here, e.g., show an error message
        console.error("Failed to delete project", error);
      }
    }
  };

  const handleClear = async (projectId) => {
    const confirm = window.confirm("Are you sure you want to clearn the names from this project?");

    if (confirm) {
      try {
        const response = await fetch(`${host}/api/projects/${projectId}/clear`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          // Project successfully deleted, you can update the UI accordingly
          fetchData(); // Refetch data to update the project list
        } else {
          // Handle error here, e.g., show an error message
          console.error("Failed to delete project");
        }
      } catch (error) {
        // Handle error here, e.g., show an error message
        console.error("Failed to delete project", error);
      }
    }
  };

  const sortedNameData = [...data].sort((a, b) => {
    if (sortColumn) {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      return compareValues(valueA, valueB);
    }
    return 0;
  });
  if (loading) {
    return <span className="loading loading-ring loading-xs"></span>;
  }

  return (
    <div className="container mx-auto w-full pl-5">
      <div className="text-sm breadcrumbs pb-5">
        <ul>
          <li>
            <Link className="text-secondary" to={`/`}>
              <FontAwesomeIcon icon={faHome} size="1x" />
              <p className="pl-2 text-secondary">Home</p>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/projects`}>
              <FontAwesomeIcon icon={faFolder} size="1x" className="secondary" />
              <p className="pl-2 text-secondary">Projects</p>
            </Link>
          </li>
        </ul>
      </div>
      {loading ? (
        <span className="loading loading-ring loading-lg"></span>
      ) : (
        <div className="w-full lg:w-1/2 p-5">
          <div className="flex justify-center pb-5">
            <Link className="text-secondary" to="/form" onClick={scrollToTop}>
              <button className="btn btn-secondary text-neutral  ">CREATE</button>
            </Link>
          </div>
          {sortedNameData.length > 0 ? (
            <table className="table table-xs">
              <thead>
                <tr>
                  <th className="px-2 py-1 cursor-pointer" onClick={() => handleSort("id")}>
                    id {sortColumn === "id" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="px-2 py-1 cursor-pointer" onClick={() => handleSort("name")}>
                    Name {sortColumn === "name" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="px-2 py-1 cursor-pointer" onClick={() => handleSort("status")}>
                    Status {sortColumn === "status" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  {/* <th className="px-2 py-1 cursor-pointer" onClick={() => handleSort("timestamp")}>
                    Date {sortColumn === "timestamp" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th> */}
                  {/* <th className="px-2 py-1 cursor-pointer" onClick={() => handleSort("client")}>
                    Client {sortColumn === "client" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="px-2 py-1 cursor-pointer" onClick={() => handleSort("category")}>
                    Category {sortColumn === "category" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th> */}
                  <th className="px-2 py-1 cursor-pointer" onClick={() => handleSort("names_count")}>
                    Names Generated {sortColumn === "names_count" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  <th className="px-2 py-1 cursor-pointer" onClick={() => handleSort("bookmarked_percent")}>
                    Names Liked {sortColumn === "bookmarked_percent" && (sortDirection === "asc" ? "↑" : "↓")}
                  </th>
                  {/* <th className="px-2 py-1 cursor-pointer" onClick={() => handleSort("bookmarked_percent")}>
                    Delete Project
                  </th>
                  <th className="px-2 py-1 cursor-pointer" onClick={() => handleSort("bookmarked_percent")}>
                    Clear Names
                  </th> */}
                </tr>
              </thead>

              <tbody>
                {sortedNameData.map((project) => (
                  <tr key={project.id}>
                    <td className="border px-4 py-2">{project.id}</td>
                    <td className="border px-4 py-2">
                      <Link className="text-secondary bold" to={`/projects/${project.id}`}>
                        {project.name}
                      </Link>
                    </td>
                    <td className="border px-4 py-2 capitalize">{["generate", "mutate"].includes(project.status) ? <div className="pulse-icon"></div> : project.status}</td>
                    {/* <td className="border px-4 py-2">{new Date(project.timestamp).toLocaleDateString()}</td> */}
                    {/* <td className="border px-4 py-2">{project.client}</td> */}
                    {/* <td className="border px-4 py-2">{project.category}</td> */}
                    <td className="border px-4 py-2">{project.names_count}</td>
                    <td className="border px-4 py-2">{project.bookmarked_percent.toFixed(2)}%</td>
                    {/* 
                    <td className="border px-4 py-2">
                      <FontAwesomeIcon icon={faTrash} size="1x" className="text-secondary" onClick={() => handleDelete(project.id)} />
                    </td>
                    <td className="border px-4 py-2">
                      <FontAwesomeIcon icon={faRefresh} size="1x" className="text-secondary" onClick={() => handleClear(project.id)} />
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <span className="loading loading-ring loading-xs"></span>
          )}
        </div>
      )}
    </div>
  );
}

export default Projects;
