import React, { useEffect, useState, useRef } from "react";
import * as d3 from "d3";

function Visual() {
  const [data, setData] = useState(null);
  const host = process.env.REACT_APP_SERVER;
  const svgRef = useRef();

  useEffect(() => {
    fetch(`${host}/api/visuals`)
      .then((response) => response.json())
      .then((data) => setData(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    if (data) {
      console.log(data);

      const width = 1200;
      const height = 800;

      const pack = (data) =>
        d3.pack().size([width, height]).padding(3)(
          d3
            .hierarchy(data)
            .sum((d) => d.value)
            .sort((a, b) => b.value - a.value)
        );
      const root = pack(data);
      const svg = d3.select(svgRef.current).attr("width", width).attr("height", height).append("g");

      const node = svg
        .selectAll("circle")
        .data(root.descendants().slice(1))
        .enter()
        .append("circle")
        .attr("fill", (d) => {
          return d.children ? "none" : d.data.bookmarked ? "#c76f31" : "#7e88a5";
        })
        .attr("opacity", (d) => {
          return d.children ? 0 : d.data.bookmarked ? 1 : 0.2;
        })
        // .attr("opac  ity", 0.2)
        .attr("pointer-events", (d) => (d.children ? "none" : null))
        .attr("cx", (d) => d.x)
        .attr("cy", (d) => d.y)

        .attr("r", (d) => {
          return d.data.bookmarked ? d.r * 2 : d.r * 1.4;
        })
        .on("mouseover", function () {
          d3.select(this).attr("stroke", "#000");
        })
        .on("mouseout", function () {
          d3.select(this).attr("stroke", null);
        });

      const label = svg
        .style("font", "GT-Pressura-Mono")
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .selectAll("text")
        .data(root.descendants().slice(1))
        .enter()
        .append("text")
        .style("fill-opacity", (d) => (d.children ? 1 : 0))
        .style("display", (d) => (d.children ? "inline" : "none"))
        .style("font-size", (d) => {
          if (d.parent === root) {
            return "50px";
          } else {
            return "8px";
          }
        })
        .attr("x", (d) => d.x)
        .attr("y", (d) => d.y)
        .text((d) => d.data.name);
      // .text((d) => (d.data.name ? d.data.name.slice(0, 9) : ""));
    }
  }, [data]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <svg id="visualization" ref={svgRef}></svg>
    </div>
  );
}

export default Visual;
