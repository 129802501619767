import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faHome, faFolder, faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function StatsPage() {
  const { projectId, nameId } = useParams();
  const [modelStats, setModelStats] = useState([]);
  const [mutationStats, setMutationStats] = useState([]);
  const host = process.env.REACT_APP_SERVER;

  useEffect(() => {
    const fetchModelStats = async () => {
      try {
        const response = await fetch(`${host}/api/projects/${projectId}/modelStats`);
        const jsonData = await response.json();
        setModelStats(jsonData.data.modelStats);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchMutationStats = async () => {
      try {
        const response = await fetch(`${host}/api/projects/${projectId}/mutationStats`);
        const jsonData = await response.json();
        setMutationStats(jsonData.data.mutationStats);
      } catch (error) {
        console.error(error);
      }
    };

    fetchModelStats();
    fetchMutationStats();
  }, []);

  return (
    <div className="container mx-auto w-full pl-5">
      <div className="text-sm breadcrumbs pb-5">
        <ul>
          <li>
            <Link className="text-secondary" to={`/`}>
              <FontAwesomeIcon icon={faHome} size="1x" />
              <a className="pl-2">Home</a>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/projects`}>
              <FontAwesomeIcon icon={faFolder} size="1x" />
              <a className="pl-2">Projects</a>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/projects/${projectId}`}>
              <a>{projectId}</a>
            </Link>
          </li>
          <li>
            <Link className="text-secondary" to={`/projects/${projectId}/stats`}>
              <a>Stats</a>
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/2 p-5">
          <div>
            <article className="prose ">
              <h3>Stats</h3>
            </article>
            <br />

            {modelStats?.length > 0 && (
              <div>
                <article className="prose ">
                  <h4>Models</h4>
                </article>
                <table className="table table-xs">
                  <thead>
                    <tr>
                      <th className="px-4 py-2">Model</th>
                      <th className="px-4 py-2">Total Count</th>
                      <th className="px-4 py-2">Percentage Total</th>
                      <th className="px-4 py-2">Likes Count</th>
                      <th className="px-4 py-2">Precision</th>
                      <th className="px-4 py-2">Recall</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modelStats.map((stat, index) => (
                      <tr key={index}>
                        <td className="border px-4 py-2">{stat.model}</td>
                        <td className="border px-4 py-2">{stat.count}</td>
                        <td className="border px-4 py-2">{stat.percentageTotal}%</td>
                        <td className="border px-4 py-2">{stat.likesCount}</td>
                        <td className="border px-4 py-2">{stat.precision}%</td>
                        <td className="border px-4 py-2">{stat.recall}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <br />
            {mutationStats?.length > 0 && (
              <div>
                <article className="prose ">
                  <h4>Mutations</h4>
                </article>
                <table className="table table-xs">
                  <thead>
                    <tr>
                      <th className="px-4 py-2">Mutation</th>
                      <th className="px-4 py-2">Total Count</th>
                      <th className="px-4 py-2">Percentage Total</th>
                      <th className="px-4 py-2">Likes Count</th>
                      <th className="px-4 py-2">Precision</th>
                      <th className="px-4 py-2">Recall</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mutationStats.map((stat, index) => (
                      <tr key={index}>
                        <td className="border px-4 py-2">{stat.mutation}</td>
                        <td className="border px-4 py-2">{stat.count}</td>
                        <td className="border px-4 py-2">{stat.percentageTotal}%</td>
                        <td className="border px-4 py-2">{stat.likesCount}</td>
                        <td className="border px-4 py-2">{stat.precision}%</td>
                        <td className="border px-4 py-2">{stat.recall}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsPage;
