import React, { useEffect } from "react";

const DynamicSVGIcon = () => {
  useEffect(() => {
    const updateIconColor = async () => {
      const isDev = process.env.NODE_ENV === "development";

      // Load the SVG from the file
      const response = await fetch("icon.svg"); // Update the path to your SVG file
      const svgText = await response.text();

      // Parse the SVG text and update the fill attributes
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
      const paths = svgDoc.querySelectorAll("path");

      paths.forEach((path) => {
        // Invert colors based on environment
        const currentFill = path.getAttribute("fill");
        const newFill = isDev ? invertColor(currentFill) : currentFill;
        console.log("currentFill: ", currentFill, "newFill: ", newFill);

        path.setAttribute("fill", newFill);
      });

      const modifiedSvgText = new XMLSerializer().serializeToString(svgDoc.documentElement);

      // Create a new Blob and Object URL
      const svgBlob = new Blob([modifiedSvgText], { type: "image/svg+xml" });
      const url = URL.createObjectURL(svgBlob);

      // Create and set the favicon link
      const link = document.createElement("link");
      link.rel = "icon";
      link.type = "image/svg+xml";
      link.href = url;

      document.head.appendChild(link);

      // Clean up old favicons
      return () => {
        document.head.removeChild(link);
        URL.revokeObjectURL(url);
      };
    };

    const invertColor = (color) => {
      if (!color || color === "none") return "#000000";

      // Ensure the color string starts with '#'
      const hexColor = color.startsWith("#") ? color.slice(1) : color;

      // Check if hexColor length is either 3 or 6
      if (hexColor.length !== 3 && hexColor.length !== 6) return color;

      // If the color is shorthand (3 characters), convert it to full form (6 characters)
      const fullHexColor =
        hexColor.length === 3
          ? hexColor
              .split("")
              .map((char) => char + char)
              .join("")
          : hexColor;

      // Extract the red, green, and blue components
      const r = parseInt(fullHexColor.slice(0, 2), 16);
      const g = parseInt(fullHexColor.slice(2, 4), 16);
      const b = parseInt(fullHexColor.slice(4, 6), 16);

      // Invert the color components
      const invertedR = (255 - r).toString(16).padStart(2, "0");
      const invertedG = (255 - g).toString(16).padStart(2, "0");
      const invertedB = (255 - b).toString(16).padStart(2, "0");

      // Return the inverted color
      return `#${invertedR}${invertedG}${invertedB}`;
    };

    updateIconColor();
  }, []);

  return null;
};

export default DynamicSVGIcon;
